<template>
  <VCard
    ref="card"
    class="pa-2 py-0 overflow-auto"
    elevation="4"
    height="100%"
  >
    <VCardText class="pa-0 full-height">
      <CMTabs
        :tabs="tabs"
        :handle-change="handleChangeTab"
      >
        <template v-if="!loading">
          <VTabItem>
            <CMTable
              v-if="inventory.length && currentTab === 'Assets'"
              :show-select="true"
              :page-size="15"
              :extra-styles="{ marginTop: '20px' }"
              origin="measurements.inventory"
              @onItemSelected="onItemSelected"
              @onToggleSelectAll="onToggleSelectAll"
            />
          </VTabItem>

          <VTabItem>
            <MSMeasurementsTab v-if="currentTab === 'Measurements'" />
          </VTabItem>

          <VTabItem>
            <MSEventsTab v-if="currentTab === 'Events'" />
          </VTabItem>
        </template>
      </CMTabs>

      <VSheet
        v-if="loading"
        height="80%"
        class="d-flex align-center justify-center"
      >
        <CMLoader
          :loading="loading"
          :overlay="false"
          height="80%"
        />
      </VSheet>

      <VSheet
        v-if="hasEmptyMessage"
        height="80%"
        class="d-flex align-center justify-center"
      >
        <VRow>
          <VCol cols="12">
            <span class="title">{{ $t('grid_monitor.empty_tree') }}</span>
          </VCol>
          <VCol cols="12" v-if="!selectedStationsIds.length">
            <VBtn
              color="primary"
              dark
              v-bind="attrs"
              small
              v-on="on"
              @click="openNetworkMenu"
            >
              {{ $t('grid.network') }}
            </VBtn>
          </VCol>
        </VRow>
      </VSheet>
    </VCardText>
  </VCard>
</template>

<script>
import MSTableMixin from '@/mixins/Measurements/MSTableMixin'
import MSCommonTabs from '@/mixins/Measurements/MSCommonTabs'
import vuexMixin from '@/mixins/vuexMixin'
import { startCase } from 'lodash'
import { getElementDetails } from '@/services/measurements'

export default {
  name: 'MSTable',

  components: {
    CMTabs: () => import('@/components/Common/CMTabs.vue'),
    CMLoader: () => import('@/components/Common/CMLoader.vue'),
    MSMeasurementsTab: () => import('@/components/Measurements/MSMeasurementsTab.vue'),
    MSEventsTab: () => import('@/components/Measurements/MSEventsTab.vue')
  },

  mixins: [MSTableMixin, MSCommonTabs, vuexMixin],

  props: {
    heightPercentage: {
      type: Number,
      default: 0
    }
  },

  computed: {
    tabs () {
      const { tabs } = this.currentSelector
      const options = [
        { title: 'Assets', translation: this.$t('grid_monitor.assets') },
        { title: 'Measurements', translation: this.$t('measurements') },
        { title: 'Events', translation: this.$t('events') }
      ]

      return options.map(option => {
        const isActive = tabs.includes(option.title)

        return {
          ...option,
          active: isActive,
          disabled: !isActive
        }
      })
    },

    hasEmptyMessage () {
      return (
        (!this.currentSelector.tabs.length && !this.loading) ||
        (!this.inventory.length && !this.loading)
      )
    }
  },

  methods: {
    handleChangeTab (val) {
      const { drawElementsOnMap } = this.$store.state.measurements
      const instant = drawElementsOnMap[val.title]?.instant
      if (this.currentTab) {
        if (drawElementsOnMap[this.currentTab]) {
          drawElementsOnMap[this.currentTab].instant = this.$store.state.instant
          this.setVuexElement({
            path: 'measurements.drawElementsOnMap',
            value: drawElementsOnMap
          })
        }
        this.clearTimeSelection()
      }

      this.setVuexElement({
        path: 'instant',
        value: instant
      })

      this.setVuexElement({
        path: 'measurements.currentTab',
        value: val
      })

      this.setVuexElement({
        path: 'measurements.selected',
        value: {}
      })

      // Reset all selected elements on the map
      this.setVuexElement({
        path: 'syncMap',
        value: true
      })
    },

    async setCPDetails ({ ID: id }) {
      const ELEMENT_TYPE = 'connectionPoint'
      const elementDetails = await getElementDetails(ELEMENT_TYPE, id)
      const title = startCase(`${ELEMENT_TYPE} ${id}`)

      this.setVuexElement({
        path: 'measurements.elementDetails',
        value: {
          title,
          info: {
            number_of_clients: elementDetails.supplyPointsData.length,
            code: id
          }
        }
      })

      return id
    },

    async setAssetDetails (item, selection) {
      const { ID: id, STATION: station, CGP: cgp } = item

      const dictionaryType = {
        ST: 'station',
        TR: 'station',
        FE: 'fuse',
        LI: 'line',
        CP: 'connectionPoint',
        SP: 'connectionPoint'
      }

      const elementType = dictionaryType[this.currentSelector?.short]
      const elementIdOptions = {
        TR: station,
        SP: cgp
      }

      const elementDetails = await getElementDetails(
        elementType,
        elementIdOptions[
          this.currentSelector?.short
        ] || id
      )

      const title = startCase(`${elementType} ${id}`)

      this.setVuexElement({
        path: 'measurements.elementDetails',
        value: {
          title,
          info: {
            ...elementDetails,
            code: id
          }
        }
      })

      this.updateSelected(selection)

      return id
    },

    async onItemSelected (selection, props) {
      const { item, value } = props
      const isSelectorCP = this.currentSelector?.short === 'CP'
      const isAssetsTab = this.currentTab === 'Assets'

      const options = {
        setCPDetails: (isSelectorCP && value) && this.setCPDetails(item),
        setAssetDetails: (isAssetsTab && value) && this.setAssetDetails(item, selection),
        default: this.setVuexElement({
          path: 'measurements.elementDetails',
          value: {
            title: null,
            info: []
          }
        })
      }

      return Object.values(options).find(Boolean)
    },

    openNetworkMenu () {
      document
        .querySelector("#ms-search-form-network")
        .click()
    }
  }
}
</script>

<style scoped>
::v-deep .v-menu__content {
  z-index: 503 !important;
}

::v-deep .v-text-field.v-input--dense .v-label {
  top: 0 !important;
}

::v-deep .v-window .v-item-group .theme--dark .v-tabs-items {
  height: 100% !important;
}

.full-height {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.row {
  margin: 0 !important;
}
</style>
